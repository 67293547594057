<template>
  <div class="userCenter-navigation-container">
    <div style="width: 1200px;margin: auto;display: flex;justify-content: space-between">
      <div class="nav-item-box">
        <div style="height: 60px;line-height: 60px;cursor: pointer;display: flex;align-items: center" @click="toApp">
          <img src="../../assets/logo-2.png" style="margin-right: 20px" alt="">
          <span style="font-size: 20px;color: #4A4A4A;font-weight: bolder">通州教师研修网</span>
        </div>
        <div class="nav-item" :class="navId === 'userCenter' ? 'nav-item-active': ''" @click="navClick('userCenter')">个人信息</div>
        <div class="nav-item" :class="navId === 'netMessage' ? 'nav-item-active': ''" @click="navClick('netMessage')">网络发信</div>
      </div>
      <div class="nav-operate-box">
        <div class="search-input-box">
          <el-input class="custom-el-input" v-model="searchKey" placeholder="请输入内容"></el-input>
          <div class="search-btn" @click="navigatorSearch">
            <img style="width: 20px;height: 20px;vertical-align: middle" src="../../assets/search.png" alt="">
          </div>
        </div>
        <div class="dropdown-div" style="width: 160px;cursor: pointer;">
          <el-dropdown placement="bottom-start">
            <span class="el-dropdown-link" style="font-size: 16px;color: #1E1E1E;">
              {{ userInfo.trueName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div class="dropdown-item" @click="toUserCenter">个人中心</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="dropdown-item" @click="toWebMessage">网络发信</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="dropdown-item" @click="toXiaoxi">消息公告</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="dropdown-item" @click="loginOut">退出登录</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div @click="$router.push({name: 'xiaoxi'})" style="font-size: 16px;color: #1E1E1E;cursor: pointer">消息</div>
      </div>
    </div>
  </div>
</template>

<script>
  import cookieUtil from "@/util/cookieUtil"
  export default {
    name: "navigation",
    data() {
      return {
        searchKey: '',
        navId: 'userCenter',
        userInfo: {
          id: '',
          userName: '',
          trueName: '',
          schoolName: ''
        }
      }
    },
    mounted() {
      if(this.$route.name.indexOf('netMessage') > -1) {
        this.navId = 'netMessage'
      } else if (this.$route.name.indexOf('userCenter') > -1) {
        this.navId = 'userCenter'
      }
      this.$api.home.getUserInfo().then(res => {
        if (res.code == 200) {
          Object.assign(this.userInfo, res.result)
          cookieUtil.setCookie({
            key: 'userInfo',
            value: JSON.stringify(res.result)
          })
        }
      })
    },
    methods: {
      navigatorSearch() {
        let { name } = this.$route
        if (name === 'yxdongtai') {
          this.$emit('searchDondtai', this.searchKey)
        } else {
          sessionStorage.setItem("searchKey", this.searchKey);
          this.$router.push({
            name: 'yxdongtai'
          })
        }
      },
      toApp() {
        this.$router.push({
          name: 'home'
        })
      },
      toUserCenter() {
        this.$router.push({
          name: 'userCenter'
        })
      },
      toWebMessage() {
        this.$router.push({
          name: 'netMessage'
        })
      },
      toXiaoxi() {
        this.$router.push({
          name: 'xiaoxi'
        })
      },
      loginOut() {
        cookieUtil.delCookie("JSESSIONID")
        cookieUtil.delCookie("userInfo")
        this.isLogin = false
        location.href = `${location.origin}/`
      },
      navClick(pathName) {
        this.$router.push({
          name: pathName
        })
        // this.$emit('navClick', navId)
      }
    }
  }
</script>

<style lang="scss">
  .userCenter-navigation-container {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    .nav-item-box {
      display: flex;
      .nav-item {
        height: 60px;
        line-height: 60px;
        width: 124px;
        text-align: center;
        cursor: pointer;
      }
      .nav-item:hover {
        color: #409EFF;
      }
      .nav-item-active {
        color: #409EFF;
      }
    }
    .nav-operate-box {
      height: 60px;
      display: flex;
      align-items: center;
      position: relative;
      .search-input-box {
        height: 34px;
        display: flex;
        align-items: center;
        position: relative;
      }
      .custom-el-input {
        width: 360px;
        border-radius: 34px;
        input {
          border-radius: 34px!important;
          height: 34px;
        }
      }
      .search-btn {
        width: 60px;
        height: 34px;
        line-height: 34px;
        border-radius: 34px;
        background-color: #409EFF;
        color: #fff;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        text-align: center;
      }
    }
    .dropdown-box {
      width: 160px;
      .dropdown-item {
        width: 130px;
        height: 80px;
        white-space: nowrap;
      }
    }
  }
</style>